import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { APP_URLS } from 'urls';
import SadPumpkin from 'assets/images/pumpkin-312379_640.png';
import T, { Variant } from 'components/Typography';
import Icon from 'components/Icon';

const StyledNotFound = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;

const Img = styled(Icon)`
  max-width: 100%;
  max-height: 300px;
`;

const Text = styled.div`
  text-align: center;
  width: 100%;
  max-width: 350px;
`;

const NotFound = () => (
  <StyledNotFound>
    <Img src={SadPumpkin} alt="sad pumpkin" />
    <Text>
      <T variant={Variant.h3} translationKey="not_found.info" />
    </Text>
    <Button variant="contained" color="primary" href={APP_URLS.MAIN}>
      <T variant={Variant.button} translationKey="pages.main" />
    </Button>
  </StyledNotFound>
);

export default NotFound;
