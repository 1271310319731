import React from 'react';
import { ReactSVG } from 'react-svg';

interface IIcon {
  src: string;
  alt?: string;
  style?: object
}

const Icon = ({ src, alt, ...rest }: IIcon) =>
  src ? (
    src.indexOf('.svg') !== -1 && process.env.NODE_ENV !== 'test' ? (
      <ReactSVG src={src} {...rest} />
    ) : (
      <img src={src} alt={alt} {...rest} />
    )
  ) : (
    <div />
  );

export default Icon;
