import React, { Suspense } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import Routes from 'app/Routes';
import 'styles/styles.css';
import 'app/i18n';

const theme = createMuiTheme({});

const StyledLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = () => (
  <StyledLoaderWrapper>
    <CircularProgress color="secondary" size={100} />
  </StyledLoaderWrapper>
);

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
