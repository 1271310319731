import React from "react";
import { Route as ReactRoute } from "react-router-dom";

const renderRoute = (props: object, Layout: any, Component: any) =>
  Layout ? (
    <Layout {...props}>
      <Component {...props} />
    </Layout>
  ) : (
    <Component {...props} />
  );

interface IRouteProps {
  component: any;
  layout?: any;
  [x: string]: any;
}

const Route = ({ layout, component, ...rest }: IRouteProps) => (
  <ReactRoute
    render={(props) => renderRoute(props, layout, component)}
    {...rest}
  />
);

export default Route;
