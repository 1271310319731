export const colors = {
  primary: {
    light: '#7986cb',
    main: '#3f51b5',
    dark: '#303f9f',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff4081',
    main: '#f50057',
    dark: '#c51162',
    contrastText: '#fff',
  },
  darkGrey: '#3b3b3b',
  grey: '#868686',
  white: '#ffffff',
};

export const shadows = {
  header: `1px 1px ${colors.primary.dark}`,
  boxShadow: `0px 5px 9px 0px rgba(145,145,145,0.58)`,
};

export const spacers = {
  '2xs': '2px',
  xs: '4px',
  s: '8px',
  m: '16px',
  l: '24px',
  xl: '32px',
  '2xl': '48px',
  '3xl': '56px',
  '4xl': '64px',
};

const consts = {
  navbarHeight: '60px',
  footerHeight: '36px',
  sidebarWidth: '330px',
  maxLandingPageWidth: '1280px',
};

const breakpoints = {
  //max width
  xs: '575px',
  sm: '767px',
  md: '991px',
  lg: consts.maxLandingPageWidth,
};

export default {
  colors,
  shadows,
  spacers,
  consts,
  breakpoints,
};
