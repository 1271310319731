import React from 'react';
import styled from 'styled-components';

import { variables } from 'styles';
import NavBar from './NavBar';
import Footer from './Footer';
import Sidebar from './Sidebar';

const StyledLandingPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: calc(100vh - ${variables.consts.navbarHeight} - ${variables.consts.footerHeight});
  max-width: ${variables.consts.maxLandingPageWidth};
  display: flex;
  flex-direction: row;
  padding: ${variables.spacers.m} ${variables.spacers.l};
  margin: 0 auto;
`;

const StyledContent = styled.div`
  width: 100%;
`;

const LandingPageLayout = ({ children }: { children: any }) => (
  <StyledLandingPageLayout>
    <NavBar />
    <StyledContainer>
      <Sidebar />
      <StyledContent>{children}</StyledContent>
    </StyledContainer>
    <Footer />
  </StyledLandingPageLayout>
);

export default LandingPageLayout;
