import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';

import { APP_URLS } from 'urls';
import Route from 'app/Route';
import NotFound from 'pages/NotFound';
import LandingPage from 'pages/LandingPage';
import LandingPageLayout from 'layouts/LandingPageLayout';

import WebServices from 'pages/courses/it/WebServices';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={APP_URLS.COURSES.WEB_SERVICES} component={WebServices} layout={LandingPageLayout} />
      <Route exact path={APP_URLS.NOT_FOUND} component={NotFound} />
      <Redirect from="/" to={APP_URLS.COURSES.WEB_SERVICES} />
      <Redirect from="*" to={APP_URLS.NOT_FOUND} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
