import React from 'react';
import styled from 'styled-components';
import { variables } from '../styles';
import T, { Variant } from 'components/Typography';
import { useTranslation } from 'react-i18next';

const StyledFooter = styled.div`
  height: ${variables.consts.footerHeight};
  border-top: 1px solid ${variables.colors.grey};
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0 ${variables.spacers.m};
`;

const StyledFooterContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled.div`
  font-size: 0.8rem;
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <StyledFooterContent>
        <StyledText>{`${t('footer.company')} © ${new Date().getFullYear()}. ${t(
          'footer.all_rights_reserved'
        )}`}</StyledText>
        <StyledText>
          {`${t('footer.icons_made_by')} `}
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>
          {` ${t('footer.from')} `}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </StyledText>
      </StyledFooterContent>
    </StyledFooter>
  );
};

export default Footer;
