import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

// import { APP_URLS } from 'urls';
import T, { Variant } from 'components/Typography';
import Container from 'components/Container';
// import Icon from 'components/Icon';
import { variables } from 'styles';
import Icon from 'components/Icon';

import img1 from 'assets/courses/it/webServices/1.png';
import img2 from 'assets/courses/it/webServices/2.png';

const StyledOrderedList = styled.ol`
  margin-left: ${variables.spacers.m};

  li {
    margin-top: ${variables.spacers.s};
    margin-bottom: ${variables.spacers.s};
  }

  ul {
    margin-left: 20px;
  }
`;

const StyledLine = styled.div`
  > * {
    display: inline-block;

    &:first-child {
      margin-right: ${variables.spacers.xs};
    }
  }
`;

const StyledContent = styled.div`
  margin-left: ${variables.spacers.m};
`;

// //         "subtopic_2": {
// //           "title": "",
// //           "tasks_list": {
// //             "task_1": "",
// //             "task_2": "",
// //             "task_3A": "",
// //             "task_3B": "Jeżeli wyświetlone zostanie \"v12.19.0\" to oznacza to, że zainstalowana wersja Node.js jest poprawna"
// //           }
// //         }
// //       },
// //       "sources": {
// //         "title": "",
// //         "source_1": ""
// //       }
//
const WebServices = () => (
  <Container>
    <T variant={Variant.h1} text="Modelowanie i projektowanie usług WebServices (L)" />
    {/*Topic 1*/}
    <div>
      <T variant={Variant.h2} text="#1 Przygotowanie środowiska" />
      <T
        variant={Variant.p}
        text={`Node.js jest platformą utworzoną na podstawie środowiska uruchomieniowego JavaScript przeglądarki 
      internetowej Chrome, przeznaczoną do łatwego tworzenia szybkich, skalowalnych aplikacji sieciowych. 
      Podejście to oparte jest na zdarzeniach, nieblokującego modelu wejścia-wyjścia co zapewnia lekkość 
      i efektywność [1]. Niniejszy kurs został zbudowany z użyciem języka JavaScript.`}
      />
      {/*Topic 1 - Subtopic 1*/}
      <div>
        <T variant={Variant.h3} text="Instalacja środowiska uruchomieniowego Node.js" />
        <StyledOrderedList>
          <li>
            <StyledLine>
              <T variant={Variant.noStyle} text="Pobranie instalatora wersji 12.19.0 z " />
              <a href="https://nodejs.org/en/download/" target="_blank" rel="noopener noreferrer">
                https://nodejs.org/en/download/
              </a>
              .
            </StyledLine>
          </li>
          <li>
            <T
              variant={Variant.noStyle}
              text="Instalacja Node.js na maszynie lokalnej. Podczas instalacji na systemie Windows należy zaznaczyć opcję 'Automatically install the necessary tools. [...]'"
            />
          </li>
          <li>
            <StyledLine>
              <T variant={Variant.noStyle} text="Poprawność instalacji należy sprawdzić w konsoli:" />
              <code>node -v</code>
              <T
                variant={Variant.noStyle}
                text='Jeżeli wyświetlone zostanie "v12.19.0" to oznacza, że zainstalowana wersja Node.js jest poprawna.'
              />
            </StyledLine>
          </li>
        </StyledOrderedList>
      </div>
      {/*Topic 1 - Subtopic 2*/}
      <div>
        <T variant={Variant.h3} text="Instalacja aplikacji Postman" />
        <StyledOrderedList>
          <li>
            <StyledLine>
              <T variant={Variant.noStyle} text="Pobranie instalatora z " />
              <a href="https://www.postman.com/downloads/" target="_blank" rel="noopener noreferrer">
                https://www.postman.com/downloads/
              </a>
              .
            </StyledLine>
          </li>
          <li>
            <T
              variant={Variant.noStyle}
              text="Instalacja Postmana na maszynie lokalnej. Założenie konta nie jest konieczne."
            />
          </li>
          <li>
            <StyledLine>
              <T
                variant={Variant.noStyle}
                text="Poprawność instalacji należy sprawdzić poprzez wysłanie zapytania GET na adres: "
              />
              <code>http://courses.lervana.dev/test</code>
              <T
                variant={Variant.noStyle}
                text='Jeżeli wyświetlone zostanie "Hello world" to oznacza to, że instalacja Postmana przebiegła poprawnie.'
              />
            </StyledLine>
          </li>
        </StyledOrderedList>
      </div>
      {/*Topic 1 - Subtopic 3*/}
      <StyledContent>
        <T variant={Variant.h4} text="Zadanie 1. Proszę przećwiczyć korzystanie z Postmana na przykładzie tutoriala." />
        <StyledLine>
          <T variant={Variant.noStyle} text="Link: " />
          <a href="https://www.guru99.com/postman-tutorial.html" target="_blank" rel="noopener noreferrer">
            https://www.guru99.com/postman-tutorial.html
          </a>
        </StyledLine>
        <i>
          <T variant={Variant.noStyle} text='Proszę pominąć sekcję "How to Run Collections using Newman"' />
        </i>
      </StyledContent>
    </div>
    {/*Topic 2*/}
    <div>
      <T variant={Variant.h2} text="#2 Prosty serwer w Node.js" />
      <T
        variant={Variant.p}
        text={`Express.js jest prostym frameworkiem pozwalającym na tworzenie różnorodnych serwerów. Zapewnia on narzędzia i strukturę, dzięki którym tworzenie aplikacji sieciowych jest łatwiejsze i przebiega szybciej.`}
      />
      {/*Topic 1 - Subtopic 1*/}
      <div>
        <StyledContent>
          <T variant={Variant.h4} text="Zadanie 2. Przygotowanie podstawowej aplikacji" />
          <StyledOrderedList>
            <li>
              W pierwszej kolejności stwórz katalog w którym będą znajdować się wszystkie pliki aplikacji. Powinien to
              być katalog podpięty do repozytorium.
            </li>
            <li>
              Następnie w katalogu projektu wywołaj komendę <code>npm init</code>. Uruchomiony zostanie kreator
              projektu. W nawiasach wyświetlone są zaproponowane wartości domyślne. Proszę uzupełnić informacje jak
              poniżej:
              <ul>
                <li>
                  <code>package name: web-services</code>
                </li>
                <li>
                  <code>version: 1.0.0</code>
                </li>
                <li>
                  <code>entry point: index.js</code>
                </li>
                <li>
                  <code>
                    author: <i>numer_indeksu</i>
                  </code>
                </li>
                <li>
                  <code>license: UNLICENSED</code>
                </li>
              </ul>
              Wynikiem powinno być powstanie pliku package.json.
            </li>
            <li>
              W katalogu głównym aplikacji dodaj plik o nazwie <code>idnex.js</code>. To w nim utworzony zostanie
              serwer.
            </li>
            <li>
              W pliku <code>idnex.js</code> dodaj polecenie <code>console.log('Hello world');</code>. Komenda ta
              wyświetli tekst <i>Hello world</i> na konsoli po uruchomieniu aplikacji w Node.js
            </li>
            <li>
              Aplikację uruchamia się poleceniem <code>node ./index.js</code> z katalogu głównego projektu. Aby ułatwić
              development w pliku package.json można dodać skrypt uruchamiający aplikację:
              <div>
                <Icon src={img1} style={{ height: '100px' }} />
              </div>
              Dzięki temu aplikacja będzie startować po wpisaniu komendy <code>npm run dev</code>.
            </li>
            <li>
              W przypadku środowiska deweloperskiego przydatne jest obserwowanie zmian i restartowanie środowiska w
              momencie ich wykrycia. Taki efekt można osiągnąć dzięki stosowaniu pakietu Nodemon. Powinien być on
              zainstalowany jako zależność developerska. Można to zrealizować komendą:{' '}
              <code>npm install --save-dev nodemon</code>. Natępnie w package.json należy zmienić komendę uruchamiającą
              projekt na:
              <div>
                <Icon src={img2} style={{ height: '100px' }} />
              </div>
              Od tej pory po uruchomieniu projektu komendą dev aplikacja zostanie przeładowana, gdy tylko zostanie
              wykryta jakakolwiek zmiana w obserwowanych plikach.
            </li>
            <li>
              Expres.js instaluje się za pomocą komendy <code>npm install express --save</code>
            </li>
            <li>
              Korzystając z oficjalnej dokumentacji stwórz prosty serwer Express.js. Link:{' '}
              <a href="https://expressjs.com/en/starter/hello-world.html" target="_blank" rel="noopener noreferrer">
                https://expressjs.com/en/starter/hello-world.html
              </a>
            </li>
            <li>Połącz się ze swoim serwerem poprzez aplikację Postman.</li>
            <li>
              Korzystając z oficjalnej dokumentacji dodaj dwa punkty końcowe - POST pod adresem /soap oraz POST pod
              adresem /rest. Oba niech zwracają prosty tekst. Link:{' '}
              <a href="https://expressjs.com/en/starter/basic-routing.html" target="_blank" rel="noopener noreferrer">
                https://expressjs.com/en/starter/basic-routing.html
              </a>
            </li>
            <li>
              Dodaj do serwera następujące rozszerzenia:
              <ul>
                <li>
                  <a href="https://www.npmjs.com/package/helmet" target="_blank" rel="noopener noreferrer">
                    https://www.npmjs.com/package/helmet
                  </a>
                </li>
                <li>
                  <a href="https://www.npmjs.com/package/body-parser" target="_blank" rel="noopener noreferrer">
                    https://www.npmjs.com/package/body-parser
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Zmiany wepnij do repozytorium na gałąź <code>dev</code>
            </li>
            <li>
              Przygotuj <i>Pull request</i> (PR) do gałęzi <code>main</code>. Link do PR (po nadaniu odpowiednich uprawnień do repozytorium dla prowadzącego)
              prześlij na adres email prowadzącego.
            </li>
            <li>
              Jeżeli zadanie będzie wykonane poprawnie to prowadzący zaakceptuje zmiany. Jeżeli nie to należy wprowadzić poprawki oraz ponownie wysłać e-mail do prowadzącego (i tak do skutku ☺). Po akceptacji można zamknąć PR z <i>merge'm</i> zmian do <code>maina</code>.
            </li>
          </StyledOrderedList>
        </StyledContent>
      </div>
    </div>
    {/* Sources */}
    <T variant={Variant.h2} text="Źródła" />
    <T
      variant={Variant.p}
      text="[1] Cantelon M., Harter M., Holowaychuk TJ, Rajlich N. (2015). Node.js w akcji. Gliwice: Helion"
    />
  </Container>
);

export default WebServices;
