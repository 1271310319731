import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { variables } from 'styles';

const StyledContainer = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: ${variables.spacers.m};
`;

const Container = ({ children }: { children: any }) => <StyledContainer>{children}</StyledContainer>;

export default Container;
