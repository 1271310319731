import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { colors, shadows, spacers } from 'styles';

export enum Variant {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  p2,
  navbarLink,
  button,
  noStyle,
}

interface ITypography {
  className?: string;
  translationKey?: string;
  variant?: Variant;
  suffix?: string;
  text?: string;
}

type StyledTypographyProps = {
  variant?: Variant;
};

const StyledTypography = styled.div<StyledTypographyProps>`
  ${(props) =>
    (props.variant === Variant.h1 ||
      props.variant === Variant.h2 ||
      props.variant === Variant.h3 ||
      props.variant === Variant.h4 ||
      props.variant === Variant.h5 ||
      props.variant === Variant.h6) &&
    `
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
      color: ${colors.primary.main};
    `}

  ${(props) => props.variant === Variant.h1 && `text-shadow: ${shadows.header};`}

  ${(props) =>
    props.variant === Variant.h1 &&
    `
      font-size: 3rem;
      margin-top: 0.67rem;
      margin-bottom: 0.67rem;
    `}

  ${(props) =>
    props.variant === Variant.h2 &&
    `
      font-size: 2rem;
      margin-top: 2rem;
      margin-bottom: 0.83rem;
    `}

  ${(props) =>
    props.variant === Variant.h3 &&
    `
      font-size: 1.3rem;
      margin-top: 1.5rem;
      margin-bottom: 1.2rem;
    `}

  ${(props) =>
    props.variant === Variant.h4 &&
    `
      font-size: 1rem;
      margin-top: 1.33rem;
      margin-bottom: 1.33rem;
    `}

  ${(props) =>
    props.variant === Variant.h5 &&
    `
      font-size: .83rem;
      margin-top: 1.67rem;
      margin-bottom: 1.67rem;
    `}

  ${(props) =>
    props.variant === Variant.h6 &&
    `
      font-size: .67rem;
      margin-top: 2.33rem;
      margin-bottom: 2.33rem;
    `}

  ${(props) =>
    (props.variant === Variant.p || props.variant === Variant.p2) &&
    `
     margin-top: 1rem;
     margin-bottom: 1rem;
     margin-left: 0;
     margin-right: 0;
     text-align: justify;
    `}


  ${(props) =>
    props.variant === Variant.p2 &&
    `
     margin-top: 0.5rem;
     margin-bottom: 0.5rem;
    `}

  ${(props) =>
    props.variant === Variant.navbarLink &&
    `
     color: ${colors.primary.light};
     text-transform: uppercase;
     font-size: 0.9rem;
     cursor: pointer;
     
     &:hover {
      color:  ${colors.primary.light};
     }
    `}

  ${(props) =>
    props.variant === Variant.button &&
    `
     font-size: 1rem;
    `}
`;

export default ({ className, variant, translationKey, text, suffix }: ITypography) => {
  const { t } = useTranslation();

  return (
    <StyledTypography className={className} variant={variant}>
      {translationKey ? t(translationKey) : text}
      {suffix && ' ' + suffix}
    </StyledTypography>
  );
};
