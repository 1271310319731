import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { variables } from 'styles';
import { APP_URLS } from 'urls';
import Icon from 'components/Icon';
import bookIcon from 'assets/icons/book.svg';
import Typography, { Variant } from 'components/Typography';

const StyledNavBar = styled.div`
  background-color: ${variables.colors.darkGrey};
  height: ${variables.consts.navbarHeight};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavBarContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${variables.consts.maxLandingPageWidth};
  padding: ${variables.spacers.m} ${variables.spacers.l};
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  cursor: pointer;

  img {
    height: 100%;
  }
`;

const StyledLogo = styled(Icon)`
  height: 40px;
  width: 40px;

  path {
    fill: ${variables.colors.white};
  }
`;

const StyledLogoText = styled(Typography)`
  text-transform: capitalize;
  margin-left: ${variables.spacers.m};
  color: ${variables.colors.white};

  @media (max-width: ${variables.breakpoints.md}) {
    font-size: 1.5rem;
  }

  @media (max-width: ${variables.breakpoints.sm}) {
    font-size: 1.3rem;
  }

  @media (max-width: ${variables.breakpoints.xs}) {
    font-size: 1.1rem;
  }
`;

const NavBar = () => (
  <StyledNavBar>
    <NavBarContent>
      <StyledLink to={APP_URLS.MAIN}>
        <StyledLogo src={bookIcon} alt="watermelon" />
        <StyledLogoText translationKey="title" variant={Variant.h3} />
      </StyledLink>
    </NavBarContent>
  </StyledNavBar>
);

export default NavBar;
