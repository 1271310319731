import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { variables } from 'styles';
import T, { Variant } from 'components/Typography';
import Typography from 'components/Typography';
import { APP_URLS } from "../urls";

const StyledSidebar = styled.div`
  width: ${variables.consts.sidebarWidth};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 40px;
  margin-right: ${variables.spacers.m};
`;

const StyledGroup = styled.div`
  > * {
    color: ${variables.colors.darkGrey};
  }
  
  &:hover {
    > * {
      color: ${variables.colors.darkGrey};
    }
  }
`;

const StyledLink = styled(Link)`
  margin-left: ${variables.spacers.m};
  margin-top: ${variables.spacers.s};
  display: block;

  > * {
    color: ${variables.colors.primary.main};
  }
`;

const Sidebar = () => (
  <StyledSidebar>
    <StyledGroup>
      <Typography translationKey="groups.it" variant={Variant.navbarLink} />
      <StyledLink to={APP_URLS.COURSES.WEB_SERVICES}>
        <Typography translationKey="courses.web_services.title" variant={Variant.navbarLink} />
      </StyledLink>
    </StyledGroup>
  </StyledSidebar>
);

export default Sidebar;
